import Lightbox from "stimulus-lightbox"

export default class extends Lightbox {
  static values = {
    options: Object,
    selector: String
  }

  connect () {
    // We need to remove the old gallery.
    // Because Vue removes all the events on the page on page load.
    this.element.removeAttribute('lg-uid')

    super.connect()
  }

  openGallery (event) {
    event.preventDefault()
    this.lightGallery.items[0].click()
  }

  get defaultOptions () {
    return {
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: this.selectorValue || '.item'
    }
  }
}
