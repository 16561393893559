import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.boundDropdownClosed = this.dropdownClosed.bind(this)
    document.addEventListener('dropdown_controller.dropdownClosed', this.boundDropdownClosed)

    this.boundMenuClosed = this.menuClosed.bind(this)
    document.addEventListener('menu_controller.menuClosed', this.boundMenuClosed)
  }

  disconnect () {
    document.removeEventListener('dropdown_controller.dropdownClosed', this.boundDropdownClosed)
    document.removeEventListener('menu_controller.menuClosed', this.boundMenuClosed)
  }

  submitForm () {
    const form = this.element.closest('form')

    if (form.dataset.remote === 'true') {
      Rails.fire(form, 'submit')
    } else {
      form.submit()
    }
  }

  inputChanged () {
    this.inputsChanged = true
  }

  dropdownClosed (event) {
    if (event.detail.dropdownElement.contains(this.element) && this.inputsChanged) this.submitForm()
  }

  menuClosed (event) {
    if (event.detail.menuElement.contains(this.element) && this.inputsChanged) this.submitForm()
  }
}
