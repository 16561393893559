import Flatpickr from "stimulus-flatpickr"

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/themes/confetti.css"

// import the translation files and create a translation mapping
import { French } from "flatpickr/dist/l10n/fr.js"
import { english } from "flatpickr/dist/l10n/default.js"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  locales = {
    fr: French,
    en: english
  }

  connect () {
    // define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: this.locale,
      altInput: true
    }

    super.connect()
  }

  get locale () {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")]
    }
  }
}
