/* eslint no-undef:0 */

import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    object: Object
  }

  connect () {
    document.addEventListener('cookieConsentInitialized', () => {
      if (window.dataLayer) window.dataLayer.push(this.objectValue)
    })
  }
}
