/* eslint no-undef:0 */

import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  success (event) {
    if (window.dataLayer) window.dataLayer.push({ event: 'newsletter_registration' })

    this.replace(event)
  }
}
