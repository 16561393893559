import MapController from './map_controller'

import L from "leaflet"
import 'leaflet.markercluster'

export default class extends MapController {
  static targets = ["icon"]

  static values = {
    endpoint: String,
    sectors: Array
  }

  connect () {
    super.connect()

    L.control.zoom({ position: 'topright' }).addTo(this.map)

    this._fetchPins()

    this._centerMapOnSectors()

    this.accommodationSelectedCallback = (event) => {
      this._selectedAccommodationId = JSON.parse(event.detail.accommodationId)
      this._updateMarkers()
    }

    document.addEventListener('search_controller.accommodationSelected', this.accommodationSelectedCallback)
  }

  disconnect () {
    document.removeEventListener('search_controller.accommodationSelected', this.accommodationSelectedCallback)
  }

  _options = {}

  _activeClass = 'text-primary-200'
  _selectedAccommodationId = undefined

  _pinIcon = function (pin) {
    const accommodationIds = [pin.id]

    return L.divIcon({
      className: 'flex justify-center',
      html: `<i class="fas fa-map-marker-alt" style="font-size: 35px;" data-search-map-target="icon" data-accommodation-ids="${JSON.stringify(accommodationIds)}"></i>`,
      iconSize: [26.25, 35],
      iconAnchor: [13.125, 35],
      popupAnchor: [1, -20]
    })
  }

  _circleIcon = function (pin) {
    const accommodationIds = [pin.id]

    return L.divIcon({
      className: 'flex justify-center',
      html: `<i class="fas fa-dot-circle" style="font-size: 26.25px;" data-search-map-target="icon" data-accommodation-ids="${JSON.stringify(accommodationIds)}"></i>`,
      iconSize: [26.25, 26.25],
      iconAnchor: [13.125, 13.125]
    })
  }

  _clusterIcon = function (cluster) {
    const accommodationIds = cluster.getAllChildMarkers().map(marker => marker.accommodationId)

    return L.divIcon({
      className: 'flex justify-center',
      html: `<div class="absolute inset-0 mx-auto text-white flex items-center justify-center">${cluster.getChildCount()}</div><i class="fas fa-circle inline" style="font-size: 26.25px" data-search-map-target="icon" data-accommodation-ids=${JSON.stringify(accommodationIds)}></i>`,
      iconSize: [26.25, 26.25],
      iconAnchor: [13.125, 13.125]
    })
  }

  _fetchPins () {
    const markers = L.markerClusterGroup({
      iconCreateFunction: this._clusterIcon,
      animate: false,
      showCoverageOnHover: false,
      maxClusterRadius: 30
    }).on('animationend', this._updateMarkers.bind(this))

    fetch(this.endpointValue)
    .then(response => {
      response.json().then(pins => {
        pins.forEach(pin => {
          const marker = L.marker({ lat: pin.latitude, lng: pin.longitude }, { icon: pin.address_hidden ? this._circleIcon(pin) : this._pinIcon(pin) })
          const popup = L.popup({ closeButton: false })

          marker.accommodationId = pin.id

          marker.bindPopup(popup.setContent(function () {
            fetch(pin.popup_endpoint)
            .then(response => {
              if (response.ok) {
                response.text().then(text => popup.setContent(text))
              } else {
                popup.setContent(`<div class="px-2 py-3">Une erreur s'est produite</div`)
              }
            })

            // loader
            return '<div class="px-2 py-3"><i class="fas fa-spinner animate-spin"></i></div>'
          }))

          markers.addLayer(marker)
        })
      })
    })

    this.map.addLayer(markers)
  }

  _updateMarkers () {
    this.iconTargets.forEach(icon => {
      const ids = JSON.parse(icon.dataset.accommodationIds)

      if (ids.includes(this._selectedAccommodationId)) {
        icon.classList.add(this._activeClass)
      } else {
        icon.classList.remove(this._activeClass)
      }
    })
  }

  _centerMapOnSectors () {
    const validSectors = this.sectorsValue.filter(this._sectorIsValid.bind(this))

    if (!validSectors.length) return

    if (validSectors.length === 1) {
      const sector = validSectors[0]
      const latLng = L.latLng(sector.latitude, sector.longitude)

      this.map.setView(latLng, sector.zoom)
    } else {
      const bounds = L.latLngBounds(
        validSectors.map(sector => {
          return L.latLng(sector.latitude, sector.longitude)
        })
      )

      this.map.fitBounds(bounds)
    }
  }

  _sectorIsValid (sector) {
    const valid = sector.latitude !== null && sector.longitude !== null && sector.zoom !== null

    // DEBUG
    if (valid) {
      // L.circleMarker(L.latLng(sector.latitude, sector.longitude), { radius: 10 }).addTo(this.map)
    } else {
      console.error(`Sector ${sector.id}: latitude, longitude or zoom is null`)
    }

    return valid
  }
}
