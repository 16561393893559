/* eslint no-undef:0 */

import { Controller } from 'stimulus'

export default class extends Controller {
  openModal (event) {
    event.preventDefault()
    event.stopPropagation()

    Calendly.initPopupWidget({ url: 'https://calendly.com/malsh' })

    if (window.dataLayer) window.dataLayer.push({ event: 'calendly_appointment_clicked' })
  }
}
