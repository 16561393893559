import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    "modal",
    "modalContent"
  ]

  initialize () {
    this.id = undefined
  }

  show (event) {
    if (event.target.dataset.loadOnce === false || this.previousTarget !== event.currentTarget) {
      this.previousTarget = event.currentTarget
      fetch(event.currentTarget.dataset.url)
      .then((response) => response.text())
      .then((html) => {
        this.modalContentTarget.innerHTML = html
        this.id = this.modalContentTarget.firstChild.id
        document.querySelector(shbModalSelector).modal.open(`#${this.id}`)
      })
    } else {
      document.querySelector(shbModalSelector).modal.open(`#${this.id}`)
    }
  }
}
