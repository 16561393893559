/* eslint no-undef:0 */

import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  static targets = [
    'subject',
    'agentName',
    'meetingDate'
  ]

  redirectToLocation (event) {
    if (this.gtmEventValue && window.dataLayer) {
      window.dataLayer.push({
        event: this.gtmEventValue(),
        leadType: this.hasSubjectTarget ? this.subjectTarget.value : undefined,
        leadSource: window.location.toString(),
        agentName: this.hasAgentNameTarget ? this.agentNameTarget.value : undefined
      })
    }

    const [,, xhr] = event.detail

    const location = xhr.getResponseHeader('location')
    if (location) {
      window.location = location
    }
  }

  gtmEventValue () {
    if (this.hasMeetingDateTarget && this.meetingDateTarget.value !== '') return 'callback_form_sent'

    return 'ad_contact_form'
  }
}
