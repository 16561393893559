import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["button", "map"]

  initialize () {
    this.body = document.body
    this.html = document.documentElement
    this.isOpen = false
  }

  connect () {
    this.updateDom()
  }

  toggle () {
    this.isOpen = !this.isOpen

    this.updateDom()
  }

  updateDom () {
    this.mapTarget.classList.toggle("active", this.isOpen)

    if (this.hasButtonTarget) {
      this.buttonTarget.classList.toggle("active", this.isOpen)
    }

    this.body.classList.toggle("overflow-hidden-body", this.isOpen)
    this.html.classList.toggle("overflow-hidden-html", this.isOpen)
  }
}
