import { Application } from 'stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

import ScrollTo from "stimulus-scroll-to"
import ScrollReveal from "stimulus-scroll-reveal"
import Reveal from "stimulus-reveal-controller"
import Remote from "stimulus-remote-rails"
import NestedForm from 'stimulus-rails-nested-form'
import CharacterCounter from 'stimulus-character-counter'

const stimulus = Application.start()
stimulus.debug = process.env.NODE_ENV === "development"

const controllers = import.meta.glob('./*_controller.js', { eager: true })
registerControllers(stimulus, controllers)

stimulus.register("scroll-to", ScrollTo)
stimulus.register("scroll-reveal", ScrollReveal)
stimulus.register("reveal", Reveal)
stimulus.register("remote", Remote)
stimulus.register('nested-form', NestedForm)
stimulus.register('character-counter', CharacterCounter)
