import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  toggleDeletion (e) {
    e.preventDefault()

    const deletionBtn = e.currentTarget
    const wrapper = deletionBtn.closest(this.wrapperSelector)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      const input = wrapper.querySelector("input[name*='_destroy']")
      const markedForDestruction = input.value === '1'

      wrapper.classList.toggle('table-danger', !markedForDestruction)
      wrapper.querySelectorAll('select').forEach(input => {
        input.disabled = !markedForDestruction
      })

      if (markedForDestruction) {
        input.value = '0'
        deletionBtn.classList.add('btn-danger')
        deletionBtn.classList.remove('btn-success')
        deletionBtn.firstChild.classList.remove('fa-trash-undo-alt')
        deletionBtn.firstChild.classList.add('fa-trash')
      } else {
        input.value = '1'
        deletionBtn.classList.remove('btn-danger')
        deletionBtn.classList.add('btn-success')
        deletionBtn.firstChild.classList.add('fa-trash-undo-alt')
        deletionBtn.firstChild.classList.remove('fa-trash')
      }
    }
  }
}
