/* eslint no-undef:0 */

import Remote from "stimulus-remote-rails"

export default class extends Remote {
  replace (event) {
    super.replace(event)

    // DIRTY HACK: the way this has been built means that currentTarget is the previous link that was replaced
    // This means I must test that isFavorite was false (eg is now true after the callback) to trigger the datalayerPush
    // This is bad behaviour and it WILL break at some point but fuck it, I already spent too much time on this
    if (event.currentTarget.dataset.isFavorite === 'false' && window.dataLayer) {
      dataLayer.push({
        event: 'ad_bookmark',
        adName: event.currentTarget.dataset.accommodationTitle
      })
    }

    new FlashMessage(event.currentTarget.dataset.notice, 'success')
  }
}
