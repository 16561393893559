import AutocompleteController from './autocomplete_controller'
import { template } from 'lodash'

export default class extends AutocompleteController {
  static targets = [
    'cityId',
    'sectorId',
    'placeId',
    'placeType',
    'tags',
    'tagTemplate'
  ]

  connect () {
    super.connect()

    if (this.hasTagsTarget) {
      this.resourceTemplate = template(this.tagTemplateTarget.innerHTML)
    }
  }

  selectCity (event) {
    event.preventDefault()

    const title = event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(title, 'by_city_id', value)
    } else if (this.hasPlaceTypeTarget) {
      this.placeTypeTarget.value = 'Location::City'
      this.placeIdTarget.value = value
    } else {
      this.sectorIdTarget.value = null
      this.cityIdTarget.value = value
    }

    this.inputTarget.value = title
    this.closeResults()
  }

  selectSector (event) {
    event.preventDefault()

    const title = event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(title, 'by_sector_id', value)
    } else if (this.hasPlaceTypeTarget) {
      this.placeTypeTarget.value = 'Sector'
      this.placeIdTarget.value = value
    } else {
      this.cityIdTarget.value = null
      this.sectorIdTarget.value = value
    }

    this.inputTarget.value = title
    this.closeResults()
  }

  addTag (title, attribute, value) {
    const content = this.resourceTemplate({ title, attribute, value })

    this.tagsTarget.insertAdjacentHTML('beforeend', content)
  }

  removeTag (event) {
    event.stopImmediatePropagation()

    event.currentTarget.remove()
  }
}
