import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      this.element.setAttribute("target", "_blank")
      this.element.setAttribute("rel", "noopener noreferrer")
    } else {
      this.element.setAttribute("target", "_self")
    }
  }
}
