import MapController from './map_controller'

import L from "leaflet"

export default class extends MapController {
  static values = {
    addressHidden: Boolean
  }

  connect () {
    super.connect()

    if (this.addressHiddenValue) {
      const circle = L.circle(this.centerValue, { radius: 500 })

      circle.addTo(this.map)
      this.map.fitBounds(circle.getBounds())
    } else {
      const myIcon = L.divIcon({ className: 'flex justify-center', html: `<i class="fas fa-map-marker-alt" style="font-size: 35px"></i>`, iconAnchor: [13.125, 35] })

      L.marker(this.centerValue, { icon: myIcon }).addTo(this.map)
    }
  }

  _options = {
    zoom: 16,
    zoomControl: true
  }
}
