import { Controller } from 'stimulus'
import { throttle } from 'lodash-es'

export default class extends Controller {
  static targets = ["section", 'anchors']

  static values = {
    minY: String,
    className: String,
    throttleDelay: Number
  }

  initialize () {
    this.handleScroll = this.handleScroll.bind(this)
  }

  connect () {
    this.offsetValue = this.offsetValue || 200
    this.className = this.classNameValue || 'active'
    const throttleDelay = this.throttleDelayValue || 15
    this.handleScroll = throttleDelay > 0 ? throttle(this.handleScroll, throttleDelay) : this.handleScroll
  }

  handleScroll () {
    this.sectionTargets.forEach((section) => {
      if (section.getBoundingClientRect().top < this.offsetValue) {
        this.updateCurrentState(section.getAttribute("id"))
      }
    })
  }

  updateCurrentState (id) {
    this.anchorsTargets.forEach((link) => {
      link.classList.toggle(this.className, link.getAttribute("href") === "#" + id)
    })
  }
}
