import MapController from './map_controller'
import L from "leaflet"

export default class extends MapController {
  connect () {
    super.connect()

    L.marker(this.centerValue, { icon: this._icon }).addTo(this.map)
  }

  _options = {
    zoom: 12,
    dragging: false
  }

  _icon = L.divIcon({
    className: 'flex justify-center',
    html: `<i class="fas fa-map-marker-alt text-primary-200" style="font-size: 35px;"></i>`,
    iconSize: [26.25, 35],
    iconAnchor: [13.125, 35],
    popupAnchor: [1, -20]
  })
}
