import { Controller } from 'stimulus'

export default class extends Controller {
  submitForm () {
    const form = this.element.closest('form')

    if (form.dataset.remote === 'true') {
      Rails.fire(form, 'submit')
    } else {
      form.submit()
    }
  }
}
