import { Controller } from 'stimulus'

import "leaflet/dist/leaflet.css"
import "mapbox-gl/dist/mapbox-gl.css"
import L from "leaflet"
import 'mapbox-gl-leaflet'

export default class extends Controller {
  static values = {
    center: Object,
    zoom: Number
  }

  _options = {}

  connect () {
    this.map = L.map(this.element, {
      center: this.centerValue,
      zoom: this.zoomValue,
      maxZoom: 17,
      attributionControl: false,
      zoomControl: false,
      scrollWheelZoom: false,
      ...this._options
    })

    L.mapboxGL({
      style: 'mapbox://styles/malshshb/ckrnjm2j1c90f19nzlnxtl9y9',
      accessToken: 'pk.eyJ1IjoibWFsc2hzaGIiLCJhIjoiY2tybmpoMHdpMHhqdDJ2bm91MTF3NDUyNyJ9.70LF_lFuNDSnz-KdyHgh9w'
    }).addTo(this.map)
  }
}
